import { Constants } from './../utils/Constants';
import { Injector, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject, throwError, timer } from 'rxjs';
import { catchError, delay, retryWhen, scan, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { LocalStorage } from '../utils/local-storage.service';
import { Endpoints } from '../utils/constants/Endpoints';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/router";
export class AuthService {
    constructor(httpClient, injector, router) {
        this.httpClient = httpClient;
        this.injector = injector;
        this.router = router;
        this.isAuthenticated = new BehaviorSubject(false);
        this.unsubscribe$ = new Subject();
        this.localStorage = this.injector.get(LocalStorage);
    }
    setIsAuthenticated(status) {
        this.isAuthenticated.next(status);
    }
    get isAuthenticatedStatus() {
        return this.isAuthenticated.asObservable();
    }
    isSessionTimerSet() {
        return this.sessionTimer$ !== null && this.sessionTimer$ !== undefined;
    }
    login(loginForm) {
        const url = Endpoints.LOGIN_API;
        return this.httpClient.post(url, loginForm).pipe(catchError(e => throwError(e)));
    }
    signUp(user) {
        return this.httpClient.post(`${Endpoints.SIGN_UP_API}`, user);
    }
    storeLoggedInUser(emailAddress) {
        this.localStorage.setItem('email_address', emailAddress);
    }
    getLoggedInUser() {
        return this.localStorage.getItem('email_address');
    }
    storeTokens(data) {
        this.localStorage.setItem('access_token', data['access_token']);
        this.localStorage.setItem('id_token', data['id_token']);
        this.localStorage.setItem('refresh_token', data['refresh_token']);
        this.setTokenExpiryDate();
    }
    updateTokens(data) {
        this.localStorage.setItem('access_token', data['access_token']);
        this.localStorage.setItem('id_token', data['id_token']);
        this.setTokenExpiryDate();
    }
    retrieveAccessToken() {
        return this.localStorage.getItem('access_token');
    }
    retrieveIdToken() {
        return this.localStorage.getItem('id_token');
    }
    retrieveRefreshToken() {
        return this.localStorage.getItem('refresh_token');
    }
    setTokenExpiryDate() {
        const tokenExpiryDate = new Date();
        tokenExpiryDate.setHours(tokenExpiryDate.getHours() + 1);
        this.localStorage.setItem('token_expiry', tokenExpiryDate.getTime());
    }
    getTokenExpiryDate() {
        return this.localStorage.getItem('token_expiry');
    }
    isTokenExpired() {
        const tokenExpiryDate = this.getTokenExpiryDate();
        return !(+tokenExpiryDate > new Date().getTime());
    }
    refreshToken() {
        const request = {
            emailAddress: this.getLoggedInUser(),
            refresh_token: this.retrieveRefreshToken()
        };
        return this.httpClient.post(`${Endpoints.REFRESH_TOKEN_API}`, request).pipe(catchError(e => throwError('Error occured while refreshing token: ' + e.message)));
    }
    // calculate remaining time until next token refresh relative to current time
    getTimeBeforeTokenRefresh() {
        const currentTime = new Date().getTime();
        const timeBeforeTokenRefresh = +this.getTokenExpiryDate() - currentTime - Constants.REFRESH_TOKEN_TIME_BEFORE_EXPIRY;
        return timeBeforeTokenRefresh;
    }
    setSessionTimer(milliseconds) {
        this.sessionTimer$ = new Subject();
        this.sessionTimer$.pipe(startWith(milliseconds), switchMap(ms => timer(ms)), takeUntil(this.unsubscribe$)).subscribe(() => {
            // call refresh token api after x milliseconds
            this.refreshToken().pipe(retryWhen(e => e.pipe(scan((errorCount, error) => {
                // logout and stop timer after 3 retries with error response
                if (errorCount > 1) {
                    this.logout();
                    throw error;
                }
                return errorCount + 1;
            }, 0), delay(3000))), // delay retry by 3 seconds
            takeUntil(this.unsubscribe$)).subscribe(data => {
                if (data['statusCode'] === 200) {
                    this.updateTokens(data['body']);
                    this.sessionTimer$.next(Constants.REFRESH_TOKEN_TIMER); // reset timer
                }
                else {
                    console.log('Response status code: ' + data['statusCode']);
                }
            });
        });
    }
    checkUserAuthentication() {
        const token = this.localStorage.getItem('id_token');
        if (token != null && !this.isTokenExpired()) {
            this.setIsAuthenticated(true);
            // set session timer if not yet initialized
            if (!this.isSessionTimerSet()) {
                this.setSessionTimer(this.getTimeBeforeTokenRefresh());
            }
            return true;
        }
        else if (token != null && this.isTokenExpired()) {
            this.removeTokens();
        }
        return false;
    }
    logout() {
        const accessToken = this.localStorage.getItem('access_token');
        return this.httpClient.get(Endpoints.LOGOUT_API + accessToken)
            .pipe(catchError(e => throwError('Error occured while logging out: ' + e.message))).subscribe(response => {
            this.removeTokens();
            this.router.navigate(['/']);
        });
    }
    removeTokens() {
        this.localStorage.removeItem('access_token');
        this.localStorage.removeItem('refresh_token');
        this.localStorage.removeItem('id_token');
        this.localStorage.removeItem('token_expiry');
        this.localStorage.removeItem('email_address');
        this.setIsAuthenticated(false);
    }
    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i0.INJECTOR), i0.ɵɵinject(i2.Router)); }, token: AuthService, providedIn: "root" });
