import { Routes } from '@angular/router';
const ɵ0 = () => import("./index-page/index-page.module.ngfactory")
    .then(m => m.IndexPageModuleNgFactory);
const routes = [
    { path: '',
        loadChildren: ɵ0
    }
];
export class AppRoutingModule {
}
export { ɵ0 };
