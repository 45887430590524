import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';

import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {AuthService} from '../services/auth.service';
import {NotificationService} from '../utils/notification.service';
import {Spiels} from '../utils/constants/Spiels';
import {isUndefined} from 'util';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {

    constructor(
      private authService: AuthService,
      private notification: NotificationService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token: string = localStorage.getItem('token');
        
        return next.handle(request)
        .pipe(
          catchError((error: HttpErrorResponse) => {
            let errorMessage = '';

            if (error.error instanceof ErrorEvent) {
              // client-side error
              errorMessage = `Error: ${error.error.message}`;
            } else {
              // server-side error
              if ([401, 403].indexOf(error.status) !== -1) {
                this.authService.removeTokens();
                location.reload(true);
              } else if ([400].indexOf(error.status) !== -1) {
                  if (isUndefined(error.error.body)) {
                    this.notification.showErrorMsg(Spiels.GENERIC_ERROR);
                  } else if(!error.error.body.toString().includes('User not confirmed')) {
                    this.notification.showErrorMsg(error.error.body); // will not display error if it is user not confirmed error
                  }
              } else {
                // display diff error msg if url is from sign up or login
                let keywords = error.url.split("/");
                let source = keywords[keywords.length - 1];
                if (source === 'accounts' || source === 'login' || source === 'resend-verification') {
                  this.notification.showErrorMsg(Spiels.CUSTOM_GENERIC_ERROR);
                } else {
                  this.notification.showErrorMsg(Spiels.GENERIC_ERROR);
                }
              }
            }
            return throwError(error.error.body);
          }));
    }
}
