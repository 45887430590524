export class Spiels {

  // update password
  public static SUCCESS_UPDATE_PASSWORD = 'Your password has been successfully updated.';

  // delete account
  public static SUCCESS_DELETE_ACCOUNT = 'Your account has been successfully deleted.';
  public static MODAL_DELETE_ACCOUNT = 'Are you sure you want to delete your account? This will be permanently deleted from all MSM systems.';

  // account
  public static SUCCESS_SIGN_UP = 'Successfully created user. Please check email for verification link.';
  public static SUCCESS_RESEND_VERIFICATION = 'Verification link has been successfully sent to email.';

  // contact us
  public static SUCCESS_CONTACT_US = 'Your message has been received. We will try our best to respond to you as soon as we can.';

  // forgot password
  public static SUCCESS_FORGOT_PASSWORD = 'Change password link has been successfully sent to email.';

  // application form
  public static SUCCESS_SUBMIT_APPLICATION_FORM = 'Your application has been successfully submitted.';
  public static MODAL_SUBMIT_APPLICATION_FORM = 'Are you sure you want to submit this application form?';

  // application form
  public static SUCCESS_SAVE_DRAFT = 'Your application has been successfully saved as draft.';

  // change password
  public static SUCCESS_CHANGE_PASSWORD = 'Password has been successfully changed.';

  // upload
  public static FAILED_UPLOAD_RESUME = 'File was not successfully uploaded.';

  // confirm reset password
  public static SUCCESS_RESET_PASSWORD = 'Password has been successfully reset.';

  public static GENERIC_ERROR = 'An unexpected error has occurred.';

  public static CUSTOM_GENERIC_ERROR = 'An unexpected error has occurred. If issue persists, please contact administrator via contact us.';

  public static MODAL_DELETE_UPLOADED_DOCUMENT = 'Are you sure you want to delete this file?';

  // documents submission
  public static SUCCESS_SAVE_DRAFT_DOCUMENTS = 'Your document/s has been successfully saved as draft.';

  public static SUCCESS_SUBMIT_DOCUMENTS = 'Files submitted successfully!';

  public static FAILED_SUBMIT_DOCUMENTS = 'An error has occured during the upload.';

  // documents category
  public static ENTERTAINMENT_DOCUMENTS = 'DOCUMENTS FOR ENTERTAINMENT DEPARTMENT';

  public static ENGINE_DOCUMENTS = 'DOCUMENTS FOR ENGINE DEPARTMENT';

  public static HOTEL_DOCUMENTS = 'DOCUMENTS FOR HOTEL DEPARTMENT';

  public static DECK_DOCUMENTS = 'DOCUMENTS FOR DECK DEPARTMENT';

  // canDeactivate guard message
  public static SAVE_CHANGES = 'Would you like to save your changes?';
}
