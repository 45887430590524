import {Constants} from './../utils/Constants';
import {Injectable, Injector, OnDestroy} from '@angular/core';
import {BehaviorSubject, Observable, Subject, throwError, timer} from 'rxjs';
import {catchError, delay, retryWhen, scan, startWith, switchMap, takeUntil} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {LocalStorage} from '../utils/local-storage.service';
import {Router} from '@angular/router';
import {User} from '../sign-up/model/User';
import {Endpoints} from '../utils/constants/Endpoints';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy {
  private isAuthenticated = new BehaviorSubject<Boolean>(false);
  private sessionTimer$: Subject<any>;
  private localStorage: LocalStorage;
  private unsubscribe$ = new Subject<void>();

  constructor(private httpClient: HttpClient,
    private injector: Injector,
    private router: Router) {
    this.localStorage = this.injector.get(LocalStorage);
  }

  public setIsAuthenticated(status: Boolean) {
    this.isAuthenticated.next(status)
  }

  public get isAuthenticatedStatus(): Observable<Boolean> {
    return this.isAuthenticated.asObservable();
  }

  public isSessionTimerSet() {
    return this.sessionTimer$ !== null && this.sessionTimer$ !== undefined;
  }

  public login(loginForm): Observable<ApiResponse> {
    const url = Endpoints.LOGIN_API;
    return this.httpClient.post<ApiResponse>(url, loginForm).pipe(
      catchError(e => throwError(e))
    );
  }

  public signUp(user): any {
    return this.httpClient.post<User>(`${Endpoints.SIGN_UP_API}`, user);
  }

  public storeLoggedInUser(emailAddress: string) {
    this.localStorage.setItem('email_address', emailAddress);
  }

  public getLoggedInUser() {
    return this.localStorage.getItem('email_address');
  }

  public storeTokens(data) {
    this.localStorage.setItem('access_token', data['access_token']);
    this.localStorage.setItem('id_token', data['id_token']);
    this.localStorage.setItem('refresh_token', data['refresh_token']);
    this.setTokenExpiryDate();
  }

  public updateTokens(data) {
    this.localStorage.setItem('access_token', data['access_token']);
    this.localStorage.setItem('id_token', data['id_token']);
    this.setTokenExpiryDate();
  }

  public retrieveAccessToken() {
    return this.localStorage.getItem('access_token');
  }

  public retrieveIdToken() {
    return this.localStorage.getItem('id_token');
  }

  public retrieveRefreshToken() {
    return this.localStorage.getItem('refresh_token');
  }

  public setTokenExpiryDate() {
    const tokenExpiryDate = new Date();
    tokenExpiryDate.setHours(tokenExpiryDate.getHours() + 1);
    this.localStorage.setItem('token_expiry', tokenExpiryDate.getTime())
  }

  public getTokenExpiryDate() {
    return this.localStorage.getItem('token_expiry');
  }

  isTokenExpired() {
    const tokenExpiryDate = this.getTokenExpiryDate();
    return !(+tokenExpiryDate > new Date().getTime());
  }

  public refreshToken(): Observable<ApiResponse> {
    const request = {
      emailAddress: this.getLoggedInUser(),
      refresh_token: this.retrieveRefreshToken()
    }
    return this.httpClient.post<ApiResponse>(`${Endpoints.REFRESH_TOKEN_API}`, request).pipe(
      catchError(e => throwError('Error occured while refreshing token: ' + e.message))
    );
  }

  // calculate remaining time until next token refresh relative to current time
  public getTimeBeforeTokenRefresh() {
    const currentTime = new Date().getTime();
    const timeBeforeTokenRefresh = +this.getTokenExpiryDate() - currentTime - Constants.REFRESH_TOKEN_TIME_BEFORE_EXPIRY;
    return timeBeforeTokenRefresh;
  }

  public setSessionTimer(milliseconds: number) {
    this.sessionTimer$ = new Subject();

    this.sessionTimer$.pipe(
      startWith(milliseconds),
      switchMap(ms => timer(ms)),
      takeUntil(this.unsubscribe$)
    ).subscribe(() => {
      // call refresh token api after x milliseconds
      this.refreshToken().pipe(
        retryWhen(e => e.pipe(
          scan((errorCount, error) => {
            // logout and stop timer after 3 retries with error response
            if (errorCount > 1) {
              this.logout();
              throw error;
            }
            return errorCount + 1;
          }, 0),
          delay(3000))), // delay retry by 3 seconds
          takeUntil(this.unsubscribe$)
      ).subscribe(
        data => {
          if (data['statusCode'] === 200) {
            this.updateTokens(data['body']);
            this.sessionTimer$.next(Constants.REFRESH_TOKEN_TIMER); // reset timer
          } else {
            console.log('Response status code: ' + data['statusCode']);
          }
        })
    });
  }

  public checkUserAuthentication() {
    const token = this.localStorage.getItem('id_token');
    if (token != null && !this.isTokenExpired()) {
      this.setIsAuthenticated(true);

      // set session timer if not yet initialized
      if (!this.isSessionTimerSet()) {
        this.setSessionTimer(this.getTimeBeforeTokenRefresh());
      }

      return true;
    } else if (token != null && this.isTokenExpired()) {
      this.removeTokens();
    }
    return false;
  }

  public logout() {
    const accessToken = this.localStorage.getItem('access_token');
    return this.httpClient.get<any>(Endpoints.LOGOUT_API + accessToken)
      .pipe(
        catchError(e => throwError('Error occured while logging out: ' + e.message))
      ).subscribe(
        response => {
          this.removeTokens();
          this.router.navigate(['/']);
        });
  }

  public removeTokens() {
    this.localStorage.removeItem('access_token');
    this.localStorage.removeItem('refresh_token');
    this.localStorage.removeItem('id_token');
    this.localStorage.removeItem('token_expiry')
    
    this.localStorage.removeItem('email_address')
    this.setIsAuthenticated(false);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

export interface ApiResponse {
  statusCode: number;
  body: {
    id_token: string;
    refresh_token?: string;
    access_token: string;
    expires_in: number;
    token_type: string;
  }
  message: string;
}
