import * as i0 from "@angular/core";
import * as i1 from "@angular/material/snack-bar";
export class NotificationService {
    constructor(snackBar) {
        this.snackBar = snackBar;
        this.cssConfigSuccess = {
            panelClass: ['snackbar-success'],
            duration: 5000
        };
        this.cssConfigError = {
            panelClass: ['snackbar-error'],
            duration: 5000
        };
        this.cssConfigInfo = {
            panelClass: ['snackbar-info'],
            duration: 5000
        };
    }
    showSuccessMsg(message) {
        this.snackBar.open(message, 'X', this.cssConfigSuccess);
    }
    showInfoMsg(message) {
        this.snackBar.open(message, 'X', this.cssConfigInfo);
    }
    showErrorMsg(message) {
        this.snackBar.open(message, 'X', this.cssConfigError);
    }
}
NotificationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NotificationService_Factory() { return new NotificationService(i0.ɵɵinject(i1.MatSnackBar)); }, token: NotificationService, providedIn: "root" });
