export class Constants {
  // dropdown values
  public static GENDER          = ['MALE', 'FEMALE'];
  public static CIVIL_STATUS    = ['SINGLE', 'MARRIED', 'WIDOWED', 'SEPARATED'];
  public static VESSEL_TYPE     = ['PASSENGER', 'BULK', 'CARGO', 'CONTAINER', 'TANKER', 'FISHING VESSEL', 'FERRY BOAT', 'BARGS', 'TOW AND TUG BOATS'];

  public static PREFIX_MOBILE_NUM    = '+63';
  public static VALID_FILE_TYPE = ['pdf', 'doc', 'docx'];
  public static DOCUMENTS_SUBMISSION_VALID_FILE_TYPE = ['pdf', 'doc', 'docx', 'png', 'jpg', 'jpeg', 'tiff', 'odt'];
  public static VALID_FILE_SIZE = 50000;

  // validations
  public static MAX_LENGTH = 50;
  public static NAME_MAX_LENGTH = 100;
  public static HUNDRED_MAX_LENGTH = 100;
  public static EDUCATION_MAX_LENGTH = 100;
  public static SEAMAN_MAX_LENGTH = 10;
  public static MSG_MAX_LENGTH = 250;

  public static EMAIL_REGEX = /^(([a-zA-Z\-_0-9]+(\.[a-zA-Z\-_0-9]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  public static PASSWORD_REGEX = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,20}$/;
  public static NAME_REGEX = /^[-ña-zA-Z\s]*$/;
  public static PLACE_OF_BIRTH_REGEX = /^[ña-zA-Z0-9#\-.\s]*$/;
  public static AGE_REGEX = /^[1-9][0-9]{0,2}$/;
  public static YEAR_REGEX = /^[1-9][0-9]{3}$/;
  public static TOTAL_MONTHS_REGEX = /^[1-9][0-9]{0,2}$/;
  public static NUMERIC_WITH_FIVE_CHAR_REGEX = /^[0-9]{1,5}$/;
  public static NUMERIC_WITH_EIGHT_CHAR_REGEX = /^[0-9]{8}$/;
  public static NUMERIC_WITH_NINE_CHAR_REGEX = /^[0-9]{1,9}$/;
  public static NUMERIC_WITH_TEN_CHAR_REGEX = /^[0-9]{10}$/;
  public static ALPHANUMERIC_WITH_SPECIAL_CHAR_REGEX = /^[ña-zA-Z0-9\-\s]*$/; // special char - hyphen, enye, space
  public static ALPHA_WITH_SPECIAL_CHAR_REGEX = /^[ña-zA-Z\-\s]*$/; // special char - hyphen, enye, space
  public static ALPHANUMERIC_WITH_HYPEN = /^[a-zA-Z0-9\-]*$/;
  public static ALPHANUMERIC_WITH_HYPHEN_DOT_REGEX = /^[a-zA-Z0-9._\-\s]*$/; // special char - hyphen, dot, underscore, space
  public static ALPHANUMERIC_WITH_TEN_CHAR_MAX_REGEX = /^[a-zA-Z0-9]{1,10}$/;
  public static LETTERS_ONLY = /^[a-zA-Z]*$/;

  // date formats
  public static MM_YYYY_FORMAT = 'MM/YYYY';
  public static YYYY_MM_DD_FORMAT = 'yyyy-MM-dd';
  public static MM_DD_YYYY_FORMAT = 'MM-dd-yyyy';
  public static DATE_FORMATS = {
    display: {
      dateInput: 'MM/YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY',
    }
  };

   // illness checkbox
   public static ILLNESS_LIST = [
    { id: 1, name: 'Hypertension' },
    { id: 2, name: 'Diabetes' },
    { id: 3, name: 'Cardiovascular Disease' },
    { id: 4, name: 'Bone Injuries' },
    { id: 5, name: 'Back Pain' }
  ];

  // refresh token
  public static REFRESH_TOKEN_TIMER = 3300000; // 55 minutes
  public static REFRESH_TOKEN_TIME_BEFORE_EXPIRY = 300000; // 5 minutes

  // no internet connection
  public static NO_INTERNET_CONNECTION_SPIEL = 'No internet connection detected.';

  // max form array additions
  public static MAX_SEA_EXPERIENCE = 5;
  public static MAX_LAND_EXPERIENCE = 3;
  public static MAX_CHILDREN = 20;

  public static NATURE_CONCERN_LIST = [
    'Recruitment',
    'Crewing',
    'Allotment',
    'Data Privacy',
    'Account',
    'Others',
  ];
}
