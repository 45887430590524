import {environment} from '../../../environments/environment';

export class Endpoints {

  // account
  public static SIGN_UP_API = environment.baseUrl + '/accounts';
  public static LOGIN_API = environment.baseUrl + '/auth/login';
  public static REFRESH_TOKEN_API = environment.baseUrl + '/auth/refresh-token';
  public static LOGOUT_API = environment.baseUrl + '/auth/logout?access_token=';
  public static FORGOT_PASSWORD_API = environment.baseUrl + '/accounts/reset-password';
  public static CONFIRM_RESET_PASSWORD_API = environment.baseUrl + '/accounts/confirm-reset-password';
  public static CONFIRM_SIGN_UP_PASSWORD_API = environment.baseUrl + '/accounts/confirm-account';
  public static RESEND_VERIFICATION_EMAIL_API = environment.baseUrl + '/accounts/resend-verification';

  // application
  public static JOB_LIST_API = environment.baseUrl + '/jobs';
  public static SEARCH_JOB_API = environment.baseUrl + '/jobs/search';
  public static REGIONS_API = environment.baseUrl + '/master-data/regions';
  public static CITIES_API = environment.baseUrl + '/master-data/cities';
  public static NATIONALITIES_API = environment.baseUrl + '/master-data/nationalities';
  public static APPLICATION_FORM_API = environment.baseUrl + '/accounts/application-form';

  // upload
  public static PRESIGNED_URL_API = environment.baseUrl + '/accounts/application-form/upload-attachment';
  public static DOWNLOAD_LINK_API = environment.baseUrl + '/accounts/application-form/download-attachment';
  public static DOCUMENTS_PRESIGNED_URL_API = environment.baseUrl + '/accounts/document/upload-attachment';
  public static DOCUMENTS_DOWNLOAD_LINK_API = environment.baseUrl + '/accounts/document/download-attachment';
  public static DELETE_DOCUMENT_API = environment.baseUrl + '/accounts/document/delete-attachment';

  // contact us
  public static CONTACT_US_API = environment.baseUrl + '/contact-us';

  // profile
  public static USER_PROFILE_API = environment.baseUrl + '/accounts/user-profile';
  public static APPLICATION_HISTORY_API = environment.baseUrl + '/accounts/user-profile/application-history';
  public static UPDATE_PASSWORD_API = environment.baseUrl + '/accounts/change-password';
  public static DELETE_ACCOUNT_API = environment.baseUrl + '/accounts/user-profile';

  // documents submission
  public static DOCUMENTS_API = environment.baseUrl + '/accounts/document';
  public static REQUIRED_DOCUMENTS_API = environment.baseUrl + '/accounts/document/required';

}
