import { Injectable } from '@angular/core';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(public snackBar: MatSnackBar) { }

  private cssConfigSuccess: MatSnackBarConfig = {
    panelClass: ['snackbar-success'],
    duration: 5000
  };

  private cssConfigError: MatSnackBarConfig = {
    panelClass: ['snackbar-error'],
    duration: 5000
  };

  private cssConfigInfo: MatSnackBarConfig = {
    panelClass: ['snackbar-info'],
    duration: 5000
  };

  showSuccessMsg(message: string): void {
    this.snackBar.open(message, 'X', this.cssConfigSuccess);
  }

  showInfoMsg(message: string): void {
    this.snackBar.open(message, 'X', this.cssConfigInfo);
  }

  showErrorMsg(message: string): void {
    this.snackBar.open(message, 'X', this.cssConfigError);
  }
}
